// 用户模块的vuex

export default {
  namespaced: true,
  state: () => ({
    // 用户信息
    blanceInfo: ''
  }),
  mutations: {
    // 保存userInfo
    setblanceInfo (state, blanceInfo) {
      state.blanceInfo = blanceInfo
    }
  },
  actions: {},
  getters: {}
}
