import Vue from 'vue'
import {
  Pagination,
  Icon,
  Calendar,
  Cell,
  CellGroup,
  DatetimePicker,
  Popup,
  Toast,
  Button,
  Radio,
  RadioGroup,
  Loading,
  Search,
  Checkbox
} from 'vant'

Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Pagination)
Vue.use(Icon)
Vue.use(Calendar)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(DatetimePicker)
Vue.use(Popup)
Vue.use(Button)
Vue.use(Loading)
Vue.prototype.$toast = Toast
Vue.use(Search)
Vue.use(Checkbox)
