// 点餐时间模块的vuex

export default {
  namespaced: true,
  state: () => ({
    // 点餐时间
    eatTimeInfo: {}
  }),
  mutations: {
    // 保存userInfo
    setEatTimeInfo (state, eatTimeInfo) {
      state.eatTimeInfo = eatTimeInfo
    }
  },
  actions: {},
  getters: {}
}
