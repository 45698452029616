<template>
  <div style="height: 100%">
<!--    <v-header></v-header>-->
    <!--  登陆页面  -->
      <div class="login-wrap">
        <div class="login-login-wrap">
          <div class="login-order-container" >
            <div class="login-logo"><strong>登录</strong></div>
<!--            <img class="login-logo" src="@/common/imager/logo.png" alt="" />-->
            <div class="login-order-container-warp" v-if="firstLogin === 0">
              <div class="login-input">
                <span>工号：</span>
                <input
                  v-model="loginRequestData.account"
                  type="text"
                  class="order-account"
                  style="border:1px solid #C7C7C7;border-radius: 50px;width: 210px;height: 30px;line-height:30px;padding-left:10px"
                />
              </div>
              <div class="login-input">
                <span>密码：</span>
                <input
                  v-model="loginRequestData.password"
                  type="password"
                  class="order-password"
                  style="border:1px solid #C7C7C7;border-radius: 50px;width: 210px;height: 30px;line-height:30px;padding-left:10px"
                />
              </div>
              <button @click="userLogin" v-if="loginAgin">登录</button>
              <button v-else disabled><van-loading /></button>
              <div class="remember-password">
                <van-checkbox v-model="rememberPassword" shape="square"><span style="color: #ffffff">记住密码</span></van-checkbox>
              </div>
              <ul>
                <li @click="switchLogin2()" class="textButtonLeft"> &lt;忘记密码 </li>
                <li @click="switchLogin1()" class="textButtonRight"> &lt;首次登录 </li>
              </ul>
            </div>
            <div v-else-if="firstLogin === 1" class="login-order-container-warp">
              <div class="login-input">
                <span>手机号：</span>
                <div class="login-input-div">
                  <select v-model="phoneBelonging">
                    <option :value="item.value" :key="index" v-for="(item,index) in phoneBelongingList">{{item.text}}</option>
                  </select>
                  <span class="shu">|</span>
                  <input
                    v-model="loginPhone.phone"
                    type="text"
                    class="order-account"
                  />
                </div>
              </div>
              <div class="login-input">
                <span >验证码：</span>
                <div class="login-input-div">
                  <input
                    v-model="loginPhone.verification"
                    type="text"
                    class="order-password"
                  />
                  <span class="verification-code-s">|</span>
                  <span style="color: #ccc" v-show="verificationShow" @click="getCode()" >&nbsp;&nbsp;获取验证码</span>
                  <span v-show="!verificationShow">{{verificationCount}}s</span>
                </div>
              </div>
              <button @click="phoneLogin" v-if="loginAgin">登录</button>
              <button v-else disabled><van-loading /></button>
              <ul>
                <li @click="switchLogin2()" class="textButtonLeft"> &lt;忘记密码 </li>
                <li @click="switchLogin()" class="textButtonRight"> &lt;工号密码登录 </li>
              </ul>
            </div>
            <div class="login-order-container-warp" v-else>
              <div class="login-input">
                <span>工号：&nbsp;&nbsp;&nbsp;</span>
                <div class="login-input-div">
                  <input
                    v-model="loginPhoneUP.account"
                    type="text"
                    class="order-account"
                  />
                </div>
              </div>
              <div class="login-input" >
                <span>手机号：</span>
                <div class="login-input-div">
                  <select v-model="phoneBelonging" >
                    <option :value="item.value" :key="index" v-for="(item,index) in phoneBelongingList">{{item.text}}</option>
                  </select>
                  <span class="shu">|</span>
                  <input
                    v-model="loginPhoneUP.phone"
                    type="text"
                    class="order-account"
                  />
                </div>
              </div>
              <div class="login-input" >
                <span>验证码：</span>
                <div class="login-input-div">
                  <input
                    v-model="loginPhoneUP.verification"
                    type="text"
                    class="order-password"
                  />
                  <span class="verification-code-s">|</span>
                  <span style="color: #ccc" v-show="verificationShow" @click="getCodeUP()" >&nbsp;&nbsp;获取验证码</span>
                  <span v-show="!verificationShow">{{verificationCount}}s</span>
                </div>
              </div>
              <button @click="loginUpdatePassword" v-if="loginAgin">登录</button>
              <button v-else disabled><van-loading /></button>
              <ul>
                <li @click="switchLogin1()" class="textButtonLeft"> &lt;首次登录 </li>
                <li @click="switchLogin()" class="textButtonRight"> &lt;工号密码登录 </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// import VHeader from 'components/v-header/v-LoginHeader'
import { mapMutations, mapState } from 'vuex'
import {
  getUserAccount,
  webUserLogin,
  sendLoginCode,
  verificationyzmCode,
  changeFirstPassword,
  sendUpdatePassword,
  verificationCodeUP,
  getUserBalance
} from 'api'
import Vue from 'vue'
import { Toast } from 'vant'
const Base64 = require('js-base64').Base64
Vue.use(Toast)

export default {
  name: 'login',
  computed: {
    ...mapState('user', ['userInfo'])
  },
  // components: { VHeader },
  data () {
    return {
      user: {},
      loginAgin: true,
      // 登录请求参数
      loginRequestData: {
        account: '',
        password: ''
      },
      loginPhone: {
        phone: '',
        verification: ''
      },
      loginPhoneUP: {
        phone: '',
        account: '',
        verification: ''
      },
      phoneBelonging: 0,
      phoneBelongingList: [
        { text: '+86(中国)', value: 0 },
        { text: '+62(印尼)', value: 1 }
      ],
      firstLogin: 0,
      verificationShow: true,
      verificationCount: 0,
      rememberPassword: false
    }
  },
  created () {
    if (localStorage.getItem('password')) {
      // 在页面加载时从localStorage获取登录信息
      const account = localStorage.getItem('account')
      const password = Base64.decode(localStorage.getItem('password'))
      // 如果存在赋值给表单，并且将记住密码勾选
      if (account) {
        this.loginRequestData.account = account
        this.loginRequestData.password = password
        this.rememberPassword = true
      }
    }
  },
  methods: {
    ...mapMutations('user', ['setUserInfo']),
    ...mapMutations('blance', ['setblanceInfo']),
    // 记住密码
    rememberMM () {
      if (this.rememberPassword) {
        localStorage.setItem('account', this.loginRequestData.account)
        // base64加密密码
        const password = Base64.encode(this.loginRequestData.password)
        localStorage.setItem('password', password)
      } else {
        localStorage.removeItem('account')
        localStorage.removeItem('password')
      }
    },
    // 获取余额
    getUserBalance () {
      getUserBalance(this.userInfo.id)
        .then((res) => {
          console.log(res)
          if (res.code === 0) {
            this.balance = res.object.blance + ''
            if (!(this.balance.indexOf('.') === -1)) {
              const blanceArr = this.balance.split('.')
              if (blanceArr[1].length === 0) {
                blanceArr[1] = blanceArr[1] + '000'
              } else if (blanceArr[1].length === 1) {
                blanceArr[1] = blanceArr[1] + '00'
              } else if (blanceArr[1].length === 2) {
                blanceArr[1] = blanceArr[1] + '0'
              }
              this.balance = blanceArr[0] + '.' + blanceArr[1]
            } else {
              this.balance = this.balance + '.000'
            }
            this.setblanceInfo(this.balance)
          } else {
            this.$toast.fail('获取余额信息失败,请重新登录')
            this.setUserInfo({})
            // this.$router.push({ name: 'Login' })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$toast.fail('获取余额信息失败,请重新登录')
          this.setUserInfo({})
        })
    },
    // 切换登录
    switchLogin () {
      this.firstLogin = 0
    },
    switchLogin1 () {
      this.firstLogin = 1
    },
    switchLogin2 () {
      this.firstLogin = 2
    },
    // 清空登录信息
    clearLogin () {
      this.loginRequestData = {
        account: '',
        password: ''
      }
      this.firstLogin = true
      this.loginPhone = {
        phone: '', verification: ''
      }
      this.phoneBelonging = 0
      this.verificationShow = true
      this.verificationCount = 0
      this.setUserInfo({})
    },
    // 验证码获取
    getCode () {
      const toast = Toast({
        duration: 5000 // 持续展示 toast
      })
      let phoneCode = this.loginPhone.phone
      if (phoneCode === '') {
        this.$toast('请填写手机号')
      } else {
        const TIME_COUNT = 60
        if (!this.timer) {
          this.verificationCount = TIME_COUNT
          this.verificationShow = false
          this.timer = setInterval(() => {
            if (this.verificationCount > 0 && this.verificationCount <= TIME_COUNT) {
              this.verificationCount--
            } else {
              this.verificationShow = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
        if (this.phoneBelonging === 1) {
          const phoneFlag = phoneCode.substr(0, 1)
          if (phoneFlag === '0') {
            phoneCode = phoneCode.substr(1)
          }
          phoneCode = '62' + phoneCode
        }
        // 发送验证码
        sendLoginCode(phoneCode)
          .then((res) => {
            toast.message = res.message_description
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 验证码获取
    getCodeUP () {
      const toast = Toast({
        duration: 5000 // 持续展示 toast
      })
      let phoneCode = this.loginPhoneUP.phone
      const account = this.loginPhoneUP.account
      if (phoneCode === '') {
        this.$toast('请填写手机号')
      } else if (account === '') {
        this.$toast('请填写工号')
      } else {
        const TIME_COUNTUP = 60
        if (!this.timer) {
          this.verificationCount = TIME_COUNTUP
          this.verificationShow = false
          this.timer = setInterval(() => {
            if (this.verificationCount > 0 && this.verificationCount <= TIME_COUNTUP) {
              this.verificationCount--
            } else {
              this.verificationShow = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
        if (this.phoneBelonging === 1) {
          const phoneFlag = phoneCode.substr(0, 1)
          if (phoneFlag === '0') {
            phoneCode = phoneCode.substr(1)
          }
          phoneCode = '62' + phoneCode
        }
        console.log(phoneCode)
        // 发送验证码
        sendUpdatePassword(phoneCode, account)
          .then((res) => {
            toast.message = res.message_description
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 手机号登录
    phoneLogin () {
      this.loginAgin = false
      // 输入框
      const phoneCode = this.loginPhone.phone
      const verificationCode = this.loginPhone.verification
      if (phoneCode === '') {
        this.$toast('请填写手机号')
        return false
      }
      if (verificationCode === '') {
        this.$toast('请填写验证码')
        return false
      }
      verificationyzmCode({
        phone: phoneCode,
        verificationCode: verificationCode
      })
        .then((res) => {
          this.loginAgin = true
          if (res.code === -1) {
            this.$toast(res.message_description)
            return false
          }
          this.$toast('登录成功，请设置密码!')
          this.user = res.object
          // sessionStorage.setItem('loginUser', JSON.stringify(user))
          //  生成设置新密码
          this.createChangePassWindow()
          this.$emit('loginResults', 1)
        })
        .catch((err) => {
          this.loginAgin = true
          console.log(err)
          this.$toast.fail('获取用户信息失败,请重新登录')
          this.setUserInfo({})
        })
    },
    // 修改密码
    loginUpdatePassword () {
      this.loginAgin = false
      // 输入框
      const account = this.loginPhoneUP.account
      const phoneCode = this.loginPhoneUP.phone
      const verificationCode = this.loginPhoneUP.verification
      if (phoneCode === '') {
        this.$toast('请填写手机号')
        return false
      }
      if (verificationCode === '') {
        this.$toast('请填写验证码')
        return false
      }
      if (account === '') {
        this.$toast('请填写工号')
        return false
      }
      verificationCodeUP({
        account: account,
        phone: phoneCode,
        verificationCode: verificationCode
      })
        .then((res) => {
          this.loginAgin = true
          if (res.code === -1) {
            this.$toast(res.message_description)
            return false
          }
          this.$toast('登录成功，请设置密码!')
          this.user = res.object
          // sessionStorage.setItem('loginUser', JSON.stringify(user))
          //  生成设置新密码
          this.createChangePassWindow()
          this.$emit('loginResults', 1)
        })
        .catch((err) => {
          this.loginAgin = true
          console.log(err)
          this.$toast.fail('获取用户信息失败,请重新登录')
          this.setUserInfo({})
        })
    },
    createChangePassWindow () {
      this.dialogComp = this.$createDialog(
        {
          title: '首次登录设置密码',
          type: 'alert',
          confirmBtn: {
            text: '确认',
            active: true
          },
          $events: {
            confirm: () => {
              if (!this.setPassword || !this.setQRPassword) {
                this.$toast('密码不可为空')
                this.dialogComp.show()
                return false
              } else if (this.setPassword === this.setQRPassword) {
                changeFirstPassword({
                  id: this.user.id,
                  password: this.setPassword
                }).then(res => {
                  if (res.code === 0) {
                    this.$toast('修改成功')
                    const loginData = {
                      account: this.user.account,
                      password: this.setPassword
                    }
                    webUserLogin(loginData)
                      .then((res) => {
                        const data = res
                        window.localStorage.setItem('access_token', data.access_token)
                        getUserAccount(loginData).then(res => {
                          if (res.error === '-1') {
                            this.$toast(res.message_description)
                          } else {
                            this.user = res.object
                            if (this.user.isDelete === 0) {
                              this.setUserInfo(this.user)
                              this.getUserBalance()
                              this.$emit('loginResults', this.user.phone)
                            } else {
                              Toast.success('此用户以删除')
                            }
                          }
                        })
                      })
                      .catch((err) => {
                        console.log(err)
                        this.$toast('账号密码有误')
                      })
                  } else {
                    this.$toast(res.message_description)
                    this.dialogComp.show()
                    return false
                  }
                })
              } else {
                this.$toast('两次密码不一致，请重新输入')
                this.dialogComp.show()
                return false
              }
            }
          }
        },
        (createElement) => {
          return [
            createElement(
              'div',
              {
                class: {
                  'login-wrap': true
                },
                slot: 'content'
              },
              [
                createElement(
                  'div',
                  {
                    class: { 'login-item-wrap': true }
                  },
                  [
                    createElement('span', {
                      class: {
                        'password-span': true
                      },
                      domProps: {
                        innerHTML: '密码：'
                      }
                    }),
                    createElement('input', {
                      class: {
                        'password-input': true
                      },
                      attrs: {
                        type: 'password'
                      },
                      on: {
                        input: (e) => {
                          this.setPassword = e.target.value
                        }
                      }
                    })
                  ]
                ),
                createElement(
                  'div',
                  {
                    class: { 'login-item-wrap': true }
                  },
                  [
                    createElement('span', {
                      class: {
                        'password-span': true
                      },
                      domProps: {
                        innerHTML: '确认密码：'
                      }
                    }),
                    createElement('input', {
                      class: {
                        'password-input': true
                      },
                      attrs: {
                        type: 'password'
                      },
                      on: {
                        input: (e) => {
                          this.setQRPassword = e.target.value
                        }
                      }
                    })
                  ]
                ),
                createElement(
                  'div',
                  { class: { 'login-item-wrap-changePass': true } },
                  [
                    createElement('span', {
                      class: { 'confirmPassword-input': true },
                      style: { color: 'red', fontSize: '14px' },
                      domProps: { innerHTML: '注：密码长度8到20个字符，需要包含大小写字母、数字和特殊符号' }
                    })
                  ]
                )
              ]
            )
          ]
        }
      ).show()
    },
    userLogin () {
      this.loginAgin = false
      if (!this.loginRequestData.account || !this.loginRequestData.password) {
        this.$createToast({
          txt: '工号或密码输入有误',
          time: 1000
        }).show()
      } else {
        webUserLogin(this.loginRequestData)
          .then((res) => {
            const data = res
            window.localStorage.setItem('access_token', data.access_token)
            getUserAccount(this.loginRequestData).then(res => {
              if (res.code === -1) {
                this.$toast(res.message_description)
              } else {
                this.user = res.object
                if (this.user.isDelete === 0) {
                  this.loginAgin = true
                  Toast.success('登陆成功')
                  this.setUserInfo(this.user)
                  this.getUserBalance()
                  // 记住密码判断
                  this.rememberMM()
                  // 向父组件通知成功
                  this.$emit('loginResults', this.user.phone)
                } else {
                  Toast.success('此用户以删除')
                }
              }
            })
          })
          .catch((err) => {
            this.loginAgin = true
            console.log(err)
            this.$toast('账号密码有误')
          })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"
@media screen and (min-width: 300px) and (max-width: 1000px){
  .login-wrap{
    background-image url("../../common/imager/imgPhone.png")
    background-size cover
    background-position center
    background-repeat no-repeat
    //background-attachment fixed
  }
}
@media screen and (min-width: 1000px) {
  .login-wrap{
    background-image url("../../common/imager/imgPC.png")
    background-size cover
    background-position center
    background-repeat no-repeat
    //background-attachment fixed
  }
}
.login-wrap
  height 100%
  .login-login-wrap
    display: flex
    justify-content: center
    position relative
    .login-order-container
      //padding: 0 20px
      flex-direction: column
      border-radius: 5px
      display: flex
      justify-content: center
      color:#ffffff
      width: 300px
      border: none
      .login-logo
        //margin 0 auto
        //width: 50%;
        //height: 50%;
        //max-width: 100%;
        //max-height: 100%;
        //transform: scale(0.7)
        font-size 28px
        margin-left 43%
        margin-bottom 10px
      .login-order-container-warp
        margin 0 auto
        border none
        .remember-password
          margin-top 10px
          margin-left 200px
        .login-input
          padding 10px 0
          display: flex
          align-items: center
          input
            height: 24.8px
            width: 70%
            background: rgba(75, 135, 185, 0.1)
          .login-input-div
            border 1px solid #C7C7C7
            border-radius 50px
            width: 210px
            height 30px
            line-height 30px
            padding-left:10px
          select
            border: none
            height: 100%
            width: 45px
            background: rgba(75, 135, 185, 0.1)
            color:#ccc
          .verification-code-s
            color:#ccc
            margin-left -40px
          .shu
            color:#ccc
        button
          border: none
          border-radius: 30px
          overflow hidden
          background: rgba(75, 135, 185, 0.5)
          background-color #56b3f6
          width 140px
          font-size: 14px
          margin-top 10px
          margin-left 70px
          color: #fff
          height 40px
        .textButtonLeft
          margin-top 15px
          margin-left -20px
        .textButtonRight
          margin-top -20px
          margin-left 200px
</style>
