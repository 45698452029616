// 用户模块的vuex

export default {
  namespaced: true,
  state: () => ({
    // 用户信息
    userInfo: {}
  }),
  mutations: {
    // 保存userInfo
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {},
  getters: {}
}
