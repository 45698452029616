import axios from 'axios'
// import QS from 'qs'
// import store from '~store/index'

if (process.env.NODE_ENV === 'development') {
  // console.log('123456')
  // axios.defaults.baseURL = location.protocol + '/api/'// 开发
  axios.defaults.baseURL = location.protocol // 线上
} else axios.defaults.baseURL = ''
axios.defaults.timeout = 20000

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8'

export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        reject(err.data)
      })
  })
}

export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        reject(err.data)
      })
  })
}

export function postLogin (url, params) {
  const headersOption = {
    Authorization: 'Basic d3g6d3g='
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, {
        headers: Object.assign(headersOption)
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        // eslint-disable-next-line no-undef
        reject(err.data)
      })
  })
}
export function getCarryToken (url) {
  const headersOption = {
    Authorization: 'bearer' + window.localStorage.getItem('access_token')
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: Object.assign(headersOption)
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        reject(err.data)
      })
  })
}
export function PostCarryToken (url, data) {
  const headersOption = {
    Authorization: 'bearer' + window.localStorage.getItem('access_token')
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        headers: Object.assign(headersOption)
      })
      .then((res) => {
        // eslint-disable-next-line no-undef
        resolve(res.data)
      })
      .catch((err) => {
        console.log(err)
        // eslint-disable-next-line no-undef
        reject(err.data)
      })
  })
}
