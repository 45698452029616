import { get, post, postLogin, getCarryToken, PostCarryToken } from './http'

// export const apiAddress = (data) =>
// post('api/v1/users/my_address/address_edit_before', data)
// export const

// 获取菜品列表
export const getGoodsList = ({ pageSize, pageNum }) =>
  get(
    `/ManagementMenuRemote/selectManagementUser?pageNum=${pageSize}&pageSize=${pageNum}`
  )

// 获取菜品图片
export const getGoodsItemImg = (fileName) =>
  get(`/minio/presignedGetObject?fileName=${fileName}`)

// web用户登录
export const webUserLogin = (data) =>
  postLogin(`/oauth/token?grant_type=password&username=${data.account}&password=${data.password}`)

/* 获取用户详情 */
export const getUserAccount = (data) =>
  PostCarryToken('/uni/userAccount', data)

// 用户订餐
export const postRequestUserOderList = (data) =>
  PostCarryToken('/uni/insertOrderFood', data)

// 获取用户订单
export const getUserOrder = ({ pageNum, pageSize, buyersPhone }) =>
  getCarryToken(
    `/uni/selectOrderFoodDetails?pageNum=${pageNum}&pageSize=${pageSize}&buyersPhone=${buyersPhone}`
  )

// 修改订单失效
export const changOrderState = (data) =>
  PostCarryToken('/uni/updateOrderFoodInvalid', data)

// 查询包桌信息
export const getBagDeskList = (data) =>
  PostCarryToken('/ManagementMenuRemote/selectManagementTablePo', data)

// 用户订餐包桌
export const postBegDeskUserOderList = (data) =>
  PostCarryToken('/uni/insertTableOrder', data)

// 查询用户包桌订单列表 因为手机号判断所以暂时弃用
export const getUserDeskOrder = (data) =>
  PostCarryToken('/uni/selectOrderTable', data)

// 查询用户包桌订单列表
export const selectOrderTable = (data) =>
  PostCarryToken('/api/orderTable/selectOrderTable', data)

// 查询用户包桌详情
export const getUserDeskOderDetail = (data) =>
  post('/ManagementMenuRemote/selectOrderTableFood', data)

// 修改用户包桌订单状态
export const changeDeskOrderState = (data) =>
  PostCarryToken('/uni/updateByPrimaryKeySelective', data)

// 获取菜品图片
export const getCodeUserId = (code) => get('/weChat/code?code=' + code)

// 修改密码
export const changePassword = (data) => post('/ManagementMenuRemote/changePassword', {
  account: data.account,
  oldPassword: data.oldPassword,
  newPassword: data.newPassword
})

// 查询餐品/包桌详情订单
export const getOrderByOrderCOde = (orderCode) =>
  getCarryToken(
    `/uni/getOrderByOrderCOde?orderCode=${orderCode}`
  )

// 取餐
export const takeAMeal = (orderCode) =>
  getCarryToken(
    `/uni/takeAMeal?orderCode=${orderCode}`
  )

// 获取用户订单
export const getOrder = ({ pageNum, pageSize, orderCode }) =>
  getCarryToken(
    `/uni/selectOrderFoodByCode?pageNum=${pageNum}&pageSize=${pageSize}&orderCode=${orderCode}`
  )

// 发送验证码
export const sendLoginCode = (phone) =>
  get(
    `/ManagementMenuRemote/sendLoginCode?phone=${phone}`
  )

// 发送验证码 - 修改密码
export const sendUpdatePassword = (phone, account) =>
  get(
    `/ManagementMenuRemote/sendUpdatePassword?phone=${phone}&account=${account}`
  )

// 验证验证码
export const verificationyzmCode = (data) =>
  post('/ManagementMenuRemote/verificationCode', data)

// 验证验证码
export const verificationCodeUP = (data) =>
  post('/ManagementMenuRemote/verificationCodeUP', data)

// 修改密码
export const changeFirstPassword = (data) => post('/ManagementMenuRemote/changeFirstPassword', {
  id: data.id,
  password: data.password
})
// 退款
export const gotoRefund = (codeOrder) =>
  getCarryToken(
    `/uni/gotoRefund?orderCode=${codeOrder}`
  )
// 获取余额
export const getUserBalance = (userId) =>
  getCarryToken(
    `/uni/getUserBalance?userId=${userId}`
  )
