// 包桌模块的vuex

export default {
  namespaced: true,
  state: () => ({
    // 用户信息
    deskInfo: {}
  }),
  mutations: {
    // 保存userInfo
    setDeskInfo (state, deskInfo) {
      state.deskInfo = deskInfo
    }
  },
  actions: {},
  getters: {}
}
