<template>
  <div class="header">
    <div class="top-container">
      <div class="top">
        <span class="icon-zhihuicanyin"></span>
        <span>德信点菜</span>
        <span class="icon-gerenzhongxin" style="width: 120px"></span>
      </div>
    </div>
    <van-button @click="showUserManual" class="userManual" type="info" size="mini">使用手册</van-button>
    <div class="logo-container">
      <img src="@/assets/Logo.png" alt="" />
    </div>
    <div class="bottom-container">
      <div class="bottom-top">
<!--        <span class="icon-hege"></span>-->
        <img src="@/common/imager/laba.png">
        <p>火锅预订时间:08:00-17:30</p>
      </div>
      <div class="bottom-time">
        <span class="bottom-time-tag">公告</span>
        <p>
          订餐时间：{{ eatTimeInfo.startEatTime }} -
          {{ eatTimeInfo.endEatTime }}
        </p>
        <div style="margin-left: 10px">
          <Button @click="handleChangePassword"> 修改密码 </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { changePassword } from 'api'
import Vue from 'vue'
import { Search } from 'vant'
Vue.use(Search)

export default {
  name: 'v-header',
  computed: {
    ...mapState('eatTime', ['eatTimeInfo'])
  },
  data () {
    return {
      user: {
        account: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      }
    }
  },
  methods: {
    // 清空Input
    resetInput () {
      document.querySelector('.account-input').value = ''
      document.querySelector('.password-input').value = ''
      document.querySelector('.newPassword-input').value = ''
      document.querySelector('.confirmPassword-input').value = ''
    },
    // 操作手册二维码展示
    showUserManual () {
      this.$emit('userManual')
    },
    // 修改密码事件
    handleChangePassword () {
      this.aa = this.$createDialog(
        {
          type: 'confirm',
          $events: {
            confirm: () => {
              if (this.user.newPassword !== this.user.confirmPassword) {
                this.$createToast({
                  txt: '两次输入密码不一致',
                  mask: true
                }).show()
                this.user = {}
                this.resetInput()
                return
              }
              changePassword(this.user).then(res => {
                if (res.code === 0) {
                  this.$createToast({
                    txt: '修改成功',
                    mask: true
                  }).show()
                  this.user = {}
                  this.resetInput()
                } else {
                  this.$createToast({
                    txt: res.message_description,
                    mask: true
                  }).show()
                  this.user = {}
                  this.resetInput()
                }
              })
            },
            cancel: () => {
              this.user = {}
              this.resetInput()
              this.aa.hide()
            }
          }
        },
        (createElement) => {
          return [
            createElement(
              'div',
              {
                class: {
                  'login-wrap': true
                },
                slot: 'content'
              },
              [
                createElement(
                  'div',
                  {
                    class: { 'login-item-wrap-changePass': true }
                  },
                  [
                    createElement('span', {
                      class: {
                        'account-span': true
                      },
                      domProps: {
                        innerHTML: '工号：'
                      }
                    }),
                    createElement('input', {
                      class: {
                        'account-input': true
                      },
                      domProps: {
                        value: this.user.account
                      },
                      on: {
                        input: (e) => {
                          this.user.account = e.target.value
                        }
                      }
                    })
                  ]
                ),
                createElement(
                  'div',
                  {
                    class: { 'login-item-wrap-changePass': true }
                  },
                  [
                    createElement('span', {
                      class: {
                        'password-span': true
                      },
                      domProps: {
                        innerHTML: '原密码：'
                      }
                    }),
                    createElement('input', {
                      class: {
                        'password-input': true
                      },
                      attrs: {
                        type: 'password'
                      },
                      on: {
                        input: (e) => {
                          this.user.oldPassword = e.target.value
                        }
                      }
                    })
                  ]
                ),
                createElement(
                  'div',
                  { class: { 'login-item-wrap-changePass': true } },
                  [
                    createElement('span', {
                      class: { 'password-span': true },
                      domProps: { innerHTML: '新密码：' }
                    }),
                    createElement('input', {
                      class: { 'newPassword-input': true },
                      attrs: { type: 'password' },
                      on: {
                        input: (e) => {
                          this.user.newPassword = e.target.value
                        }
                      }
                    })
                  ]
                ),
                createElement(
                  'div',
                  { class: { 'login-item-wrap-changePass': true } },
                  [
                    createElement('span', {
                      class: { 'password-span': true },
                      domProps: { innerHTML: '确认密码：' }
                    }),
                    createElement('input', {
                      class: { 'confirmPassword-input': true },
                      attrs: { type: 'password' },
                      on: {
                        input: (e) => {
                          this.user.confirmPassword = e.target.value
                        }
                      }
                    })
                  ]
                ),
                createElement(
                  'div',
                  { class: { 'login-item-wrap-changePass': true } },
                  [
                    createElement('span', {
                      class: { 'confirmPassword-input': true },
                      style: { color: 'red', fontSize: '14px' },
                      domProps: { innerHTML: '注：密码长度8到20个字符，需要包含大小写字母、数字和特殊符号' }
                    })
                  ]
                )
              ]
            )
          ]
        }
      ).show()
    }
  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"

.header
  position relative

  .top-container
    height: 90px
    background: url($imgUrl + "header_bg.jpg") no-repeat
    background-size: 100%

    .top
      display: flex
      align-items: center
      justify-content: space-between
      padding: 20px
      font-size: 18px
      color: #fff

      span:nth-child(2)
        font-size: 20px
        color: $color-blue
  .userManual
    position: absolute
    top: 54%
    right: 30%
  .logo-container
    position: absolute
    width 60px
    height 60px
    top: 32%
    right: 10%
    display: flex
    align-items: center
    justify-content: center
    background: #fff
    border-radius: 10px
    box-shadow: 4px 2px 5px #ccc

    img
      position: absolute
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      transform: scale(0.7)

  .bottom-container
    height: 90px
    padding: 0 20px

    .bottom-top
      display: flex
      align-items: center
      font-weight: 600
      padding: 10px 0

      img
        width 20px
      span
        color: #FFD700
        font-size: 22px
        margin-right: 5px
      p
        font-weight: 600
        font-size 14px

    .bottom-time
      display: flex
      align-items: center
      height: 40px
      background: -webkit-linear-gradient(left, rgb(241, 226, 200), rgb(218, 185, 132))
      background: -o-linear-gradient(right, rgb(241, 226, 200), rgb(218, 185, 132))
      background: -moz-linear-gradient(right, rgb(241, 226, 200), rgb(218, 185, 132))
      background: linear-gradient(to right, rgb(241, 226, 200), rgb(218, 185, 132))
      border-radius: 5px
      overflow: hidden
      box-shadow: 1px 1px 3px #888888
      color: rgb(163, 104, 64)

      .bottom-time-tag
        padding: 4px
        font-size: 12px
        transform: scale(0.9)
        background: -webkit-linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        background: -o-linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        background: -moz-linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        background: linear-gradient(rgb(155, 95, 55), rgb(145, 45, 19));
        border-radius: 5px
        overflow hidden
        color: #fff
        margin: 0 10px
        font-weight: 600

      p
        font-weight: 600
</style>
