// 分割价格
export function cutThePrice (price) {
  let res = ''
  let i = 0
  const str = String(price)
  for (let j = str.length - 1; j >= 0; j--) {
    if (i === 3) {
      res = '.' + res
      i = 0
    }
    res = str[j] + res
    i++
  }
  return res
}
