<template>
  <div class="order-wrap">
    <div v-if="userInfo.phone" class="order-wrap">
      <div class="list-options-wrap">
        <van-radio-group v-model="radioOptionsSelected" @change="changeRadio" direction="horizontal">
          <van-radio :name="1">菜品订单</van-radio>
          <van-radio :name="0">包桌订单</van-radio>
        </van-radio-group>
      </div>
      <!-- 订单号、订单时间、订餐人姓名、总价 -->
      <cube-scroll
        class="list-content"
        ref="listContent"
        :options="scrollOptions"
      >
        <ul class="orderList-wrap">
          <li
            class="orderList-container"
            v-for="(order, index) in userOrderData.list"
            :key="index"
            @click.stop="goDetail(order)"
          >
            <div class="orderList-top-container">
              <div class="order-state">
                订单状态：<span>{{ order.auditStatus.display }}</span>
              </div>
              <button
                @click.stop="changOrderDisplay(order)"
                v-if="order.auditStatus.code === 0"
                class="order-display-button"
              >
                取消订单
              </button>
              <button v-else-if="order.auditStatus.code == 3" class="order-display-button-disable" disabled>
                已取消
              </button>
              <!--              <button v-else-if="order.auditStatus.code == 5" class="order-display-button-disable" disabled>-->
              <!--                已取餐-->
              <!--              </button>-->
            </div>
            <div class="orderList-number">
              订单号：<span>{{ order.orderCode }}</span>
            </div>
            <div class="orderList-time">
              订单时间：<span>{{ order.createTime }}</span>
            </div>
            <div class="orderList-userName">
              订餐人姓名：<span>{{ order.buyersName }}</span>
            </div>
            <div class="orderList-totalPrice" v-if="order.totalPriceView">
              总价：<span>RP{{ order.totalPriceView }}</span>
            </div>
            <div class="orderList-top-container">
              <button
                @click.stop="refund(order)"
                v-if="order.auditStatus.code === 4"
                class="order-display-button"
                style="margin-left: 50%;margin-bottom: 2%;background-color: #febf30;color: #471e00"
              >
                &nbsp;&nbsp;退款&nbsp;&nbsp;
              </button>
              <button
                @click.stop="showMealCode(order)"
                v-if="order.auditStatus.code === 4"
                class="order-display-button"
                style="margin-left: 3%;margin-bottom: 2%;color: #471e00"
              >
                &nbsp;&nbsp;取餐码&nbsp;&nbsp;
              </button>
            </div>
          </li>
        </ul>
      </cube-scroll>
      <van-popup
        v-model="mealCode"
        get-container="body"
        style="width: 310px;height: 320px"
        @close="colseMealCode()"
      >
        <div class="food-name" style="font-size: 20px;margin-left: 50px;margin-top: 5px">
          <div>订单编码:
            <span>{{ this.orderCode }}</span>
          </div>
        </div>
        <div style="border: 1px solid #e4e4e4;width: 300px;height: 280px;margin-left: 5px;margin-top: 5px">
          <div id="qrcode" class="qrcode" ref="qrCodeUrl" style="margin-left: 5px;margin-top: 5px"></div>
        </div>
      </van-popup>
      <van-pagination
        class="order-pagination"
        v-model="requestOrderData.pageNum"
        :total-items="userOrderData.total"
        :items-per-page="requestOrderData.pageSize"
        @change="changePage"
      />
    </div>
    <div v-else class="order-wrap"> <Login @loginResults="loginResults"></Login></div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { mapMutations, mapState } from 'vuex'
import {
  getUserOrder,
  changOrderState,
  getUserDeskOrder,
  sendLoginCode,
  // verificationyzmCode,
  // changeFirstPassword,
  getUserBalance,
  gotoRefund,
  changeDeskOrderState
} from 'api'
import { cutThePrice } from '@/utils/tool.js'
import { Dialog } from 'vant'
import Login from 'components/login/login'

export default {
  name: 'order',
  computed: {
    ...mapState('user', ['userInfo'])
  },
  components: {
    Login
  },
  data () {
    return {
      orderCode: '',
      mealCode: false,
      userId: '',
      verificationShow: true,
      setPassword: '',
      setQRPassword: '',
      verificationCount: 0,
      // 请求餐单列表参数
      requestOrderData: {
        pageSize: 10,
        pageNum: 1,
        buyersPhone: ''
      },
      // 用户菜单列表
      userOrderData: [],
      // cube scroll参数
      scrollOptions: {
        click: false,
        directionLockThreshold: 0
      },
      // 单选参数
      bagDeskOptions: [
        { label: '菜品订单', value: 1 },
        { label: '包桌订单', value: 0 }
      ],
      // 控制首次登录
      firstLogin: true,
      // 选择订单类别
      radioOptionsSelected: 1
    }
  },
  methods: {
    ...mapMutations('user', ['setUserInfo']),
    ...mapMutations('blance', ['setblanceInfo']),
    // 获取余额
    getUserBalance () {
      getUserBalance(this.userInfo.id)
        .then((res) => {
          if (res.code === 0) {
            this.balance = res.object.blance + ''
            console.log(!(this.balance.indexOf('.') === -1))
            if (!(this.balance.indexOf('.') === -1)) {
              const blanceArr = this.balance.split('.')
              if (blanceArr[1].length === 0) {
                blanceArr[1] = blanceArr[1] + '000'
              } else if (blanceArr[1].length === 1) {
                blanceArr[1] = blanceArr[1] + '00'
              } else if (blanceArr[1].length === 2) {
                blanceArr[1] = blanceArr[1] + '0'
              }
              this.balance = blanceArr[0] + '.' + blanceArr[1]
            } else {
              this.balance = this.balance + '.000'
            }
            this.setblanceInfo(this.balance)
          } else {
            this.$toast.fail('获取余额信息失败,请重新登录')
            this.setUserInfo({})
            // this.$router.push({ name: 'Login' })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    loginResults (e) {
      if (e === 1) {
        return false
      } else {
        this.requestOrderData.buyersPhone = e
        this.getUserOder()
        // this.$emit('loginResults', e)
      }
    },
    // 获取订单信息
    getUserOder () {
      console.log('获取订单请求参数', this.requestOrderData)
      if (this.radioOptionsSelected) {
        getUserOrder(this.requestOrderData)
          .then((res) => {
            res.object.list.forEach((item) => {
              item.totalPriceView = cutThePrice(item.amount)
            })
            this.userOrderData = res.object
            console.log(this.userOrderData)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        getUserDeskOrder(this.requestOrderData)
          .then((res) => {
            res.object.list.forEach((item) => {
              item.totalPriceView = cutThePrice(item.amount)
            })
            this.userOrderData = res.object
            console.log(this.userOrderData)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 修改页码
    async changePage (e) {
      this.requestOrderData.pageNum = e
      await this.getUserOder()
    },
    // 详情页
    goDetail (e) {
      if (this.radioOptionsSelected) {
        this.$router.push({ name: 'About', params: { detailData: e } })
      } else {
        this.$router.push({
          name: 'About',
          params: { detailData: e, isDesk: true }
        })
      }
    },
    getCode () {
      let phoneCode = this.loginPhone.phone
      if (phoneCode === '') {
        this.$toast('请填写手机号')
      } else {
        // if (!(/^1[3456789]\d{9}$/.test(phoneCode))) {
        //   this.$toast('请输入正确手机号')
        //   return false
        // }
        const TIME_COUNT = 60
        if (!this.timer) {
          this.verificationCount = TIME_COUNT
          this.verificationShow = false
          this.timer = setInterval(() => {
            if (this.verificationCount > 0 && this.verificationCount <= TIME_COUNT) {
              this.verificationCount--
            } else {
              this.verificationShow = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
        if (this.phoneBelonging === 1) {
          phoneCode = '62' + phoneCode
        }
        // 发送验证码
        sendLoginCode(phoneCode)
          .then((res) => {
            if (res.code === -1) {
              this.$toast(res.message_description)
            }
            this.$toast(res.message_description)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    // 取餐码关闭时
    colseMealCode () {
      const codeHtml = document.getElementById('qrcode')
      console.log(codeHtml)
      codeHtml.innerHTML = ''
      this.orderCode = ''
      this.getUserOder()
    },
    // 展示取餐码
    async showMealCode (e) {
      this.orderCode = e.orderCode
      let codeOrder = ''
      if (this.radioOptionsSelected) {
        codeOrder = 'C' + e.orderCode // C 代表餐品订单
      } else {
        codeOrder = 'B' + e.orderCode // B 代表包桌订单
      }
      console.log(codeOrder)
      // 根据id获取二维码订单编号加密字符
      // const res = await getOrderTableQR(e.id)
      // 获取到加密字符
      // this.orderCode = res.orderCode
      this.mealCode = true
      this.$nextTick(() => {
        // 生成二维码
        // const baseUrl = process.env.VUE_APP_TARGET
        const qrcode = new QRCode(this.$refs.qrCodeUrl, { // eslint-disable-line no-unused-vars
          text: codeOrder, // 需要转换为二维码的内容
          width: 290,
          height: 270,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    // 退款
    refund (e) {
      Dialog.confirm({
        title: '退款',
        message: '您确定要退款该订单吗?\n注：仅限订单当日内9:00-15:30退款'
      })
        .then(() => {
          this.orderCode = e.orderCode
          let codeOrder = ''
          if (this.radioOptionsSelected) {
            codeOrder = 'C' + e.orderCode // C 代表餐品订单
          } else {
            this.$toast('包桌订单仅限现场退款')
            return false
          }
          console.log(codeOrder)
          gotoRefund(codeOrder)
            .then((res) => {
              if (res.code === -1) {
                this.$toast(res.message_description)
                this.getUserOder()
              }
              this.$toast(res.message_description)
              this.getUserOder()
              this.getUserBalance()
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$toast('已取消')
        })
    },
    // 修改订单失效()
    changOrderDisplay (e) {
      this.$createDialog({
        type: 'confirm',
        content: '确认失效该订单吗?',
        $events: {
          confirm: () => {
            if (this.radioOptionsSelected) {
              changOrderState({ id: e.id, auditStatus: 'INVALID' })
                .then(async (res) => {
                  if (res.code === 0) {
                    await this.getUserOder()
                    this.$createToast({
                      txt: res.object,
                      time: 1500,
                      type: 'correct'
                    }).show()
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            } else {
              changeDeskOrderState({ id: e.id, auditStatus: 'INVALID' })
                .then(async (res) => {
                  if (res.code === 0) {
                    await this.getUserOder()
                    this.$createToast({
                      txt: res.message_description,
                      time: 1500,
                      type: 'correct'
                    }).show()
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          }
        }
      }).show()
    },
    async changeRadio (e) {
      this.radioOptionsSelected = e
      this.requestOrderData.pageNum = 1
      await this.getUserOder()
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"

.list-options-wrap
  >>> .van-radio-group
    display: flex
    justify-content: space-between
    box-sizing :border-box
    padding:15px 16%
    width 100%
    border:0px !important
    font-size: $fontsize-large
  >>> .van-radio
    padding-left:0
    padding-right:15px

.order-wrap
  height 100%
  .list-content
    padding: 0 18px
    height: 80%
    overflow: hidden
    background: $color-white
  .orderList-wrap
    width 100%
    display: flex
    flex-wrap: wrap
    .orderList-container
      width 90%
      margin: 15px auto
      background: $color-white
      border-radius: 5px
      border: 1px dashed  $color-background-sss
      .orderList-top-container
        display: flex
        border-radius: 5px
        font-size: $fontsize-large
        justify-content: space-between
        margin: 0
        .order-state
          display: flex
          font-weight: 700
          span
            font-weight: 700
            color: $color-red
        .order-display-button
          margin-right: 5px
          border-radius: 5px
          border: none
          background: $color-blue
          padding: 5px
          color: #fff
        .order-display-button-disable
          box-shadow: none
          padding: 5px
          margin-right: 5px
          border-radius: 5px
          border:none
          padding: 5px
          color: rgba(255,0,0,0.5)
          background: $color-background-sss
      div
        margin: 8px 0
        font-size: 15px
        display: flex
        align-items: center
        padding-left: 5px
        span
          font-weight: 700
      .orderList-totalPrice
        span
          color: red
  .order-pagination
    margin-top: 10px
.login-order-wrap
  display: flex
  justify-content: center
  position relative
  top: 20%
  .login-order-container
    padding: 0 20px
    flex-direction: column
    border-radius: 5px
    border: 1px solid #ccc
    background: #fff
    display: flex
    justify-content: center
    .title
      text-align: center
      font-size: 20px
      font-weight: 700
      padding: 15px
    .login-account-container
      padding 10px 0
      display: flex
      align-items: center
    .login-password-container
      padding 10px 0
      display: flex
      align-content: center
    input
      border: 2px solid #ccc
      border-radius: 5px
    button
      border: none
      border-radius: 5px
      overflow hidden
      background: #1E90FF
      width 90%
      font-size: 14px
      margin:10px auto
      padding: 4px 0
      color: #fff
</style>
