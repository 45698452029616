import Vue from 'vue'
import Vuex from 'vuex'

// 导入user子模块
import user from './modules/user.js'
import desk from './modules/desk.js'
import eatTime from './modules/headerTime.js'
import blance from './modules/blance.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    desk,
    eatTime,
    blance
  }
})
