<template>
  <div>
    <van-popup
      v-model="userManualFlag"
      get-container="body"
      style="width: 170px;height: 180px"
    >
      <img style="width: 100%;height: 90%" src="@/assets/images/userManualImg.png" alt="" />
    </van-popup>
    <v-header @userManual="userManual"></v-header>
    <div class="tab-wrapper">
      <tab :tabs="tabs"></tab>
    </div>
  </div>
</template>

<script>
import VHeader from 'components/v-header/v-header'
import Goods from 'components/goods/goods'
import Order from 'components/order/order'
import Tab from 'components/tab/tab'
import { getCodeUserId } from 'api'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'Home',
  components: { VHeader, Tab },
  data () {
    return {
      seller: {},
      orderList: {},
      code: '',
      loading: true,
      userManualFlag: false
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    tabs () {
      // 设置tab列表以及对应组件和初始数据
      return [
        { label: '菜单', component: Goods, data: this.seller },
        { label: '订单', component: Order, data: this.orderList }
      ]
    }
  },
  methods: {
    ...mapMutations('user', ['setUserInfo']),
    // 使用手册二维码展示
    userManual () {
      this.userManualFlag = true
    },
    // url?code=xxx 获取 xxx
    getQueryVariable (variable) {
      let query = ''
      let vars = ''
      let pair = ''
      query = window.location.search.substring(1)
      vars = query.split('&')
      for (let i = 0; i < vars.length; i++) {
        pair = vars[i].split('=')
        if (pair[0] === variable) {
          return pair[1]
        }
      }
      return false
    },
    // 根据code获取用户信息
    getCode (code) {
      getCodeUserId(code)
        .then(async (res) => {
          if (res.object.userId !== null) {
            // console.log(res.object.userId)
            // // window.localStorage.setItem('name', res.object.name)
            // // window.localStorage.setItem('phone', res.object.phone)
            window.localStorage.setItem('userId', res.object.userId)
            // // this.$set()set
            this.setUserInfo({
              userName: res.object.name,
              phone: res.object.phone,
              userId: res.object.userId
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
  mounted () {
    this.code = this.getQueryVariable('code')
    if (this.code !== false) {
      this.getCode(this.code)
    }
  }
}
</script>

<style lang="stylus" scoped>
.tab-wrapper
  position: fixed
  top 180px
  left: 0
  right: 0
  bottom: 0
</style>
