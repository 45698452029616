<template>
  <div class="shopcart">
    <div class="content" @click="toggleList">
      <div class="content-left">
        <div class="logo-wrapper">
          <div class="logo" :class="{ highlight: totalCount > 0 }">
            <i class="icon-ziyuan" :class="{ highlight: totalCount > 0 }"></i>
          </div>
          <div class="num" v-show="totalCount > 0">
            <bubble :num="totalCount"></bubble>
          </div>
        </div>
        <div class="price">RP {{ totalPrice }}</div>
      </div>
      <div class="content-right">
        <div @click.stop="pay" class="pay" :class="payClass" v-if="payAgain === 1">
          {{ payDesc }}
        </div>
        <div class="pay" v-else>
          <van-loading />
        </div>
      </div>
      <!-- 小球容器 -->
      <div class="ball-container">
        <div v-for="(ball, index) in balls" :key="index">
          <transition
            @before-enter="beforeDrop"
            @enter="dropping"
            @after-enter="afterDrop"
          >
            <div class="ball" v-show="ball.show">
              <div class="inner inner-hook"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <van-popup
      v-model="loginPopUp"
      get-container="#app"
      class="login-popup"
      @click-overlay="loginPopUpClose"
    >
      <div class="order-wrap">
        <Login @loginResults="loginResults"></Login>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Bubble from 'components/bubble/bubble'
import { cutThePrice } from '@/utils/tool.js'
import {
  postRequestUserOderList,
  postBegDeskUserOderList,
  getUserBalance
} from 'api'
import { mapMutations, mapState } from 'vuex'
import Login from 'components/login/login'

const BALL_LEN = 10 // 十个小球
const InnerClsHook = 'inner-hook'
const EVENT_BUY = 'buy'

// 创建小球
function createBalls () {
  const ret = []
  for (let i = 0; i < BALL_LEN; i++) {
    ret.push({
      show: false
    })
  }
  return ret
}

export default {
  name: 'shop-cart',
  props: {
    // 已选择菜品
    selectFoods: {
      type: Array,
      default () {
        return []
      }
    },
    fold: {
      // shop-cart 作为组件是否显示，true不展开显示
      type: Boolean,
      default: true
    },
    sticky: {
      // 判断是否使用Parent的hide()
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      balls: createBalls(),
      listFold: this.fold,
      loginRequestData: {
        account: '',
        password: ''
      },
      firstLogin: true,
      // 登录请求参数
      loginPhone: {
        phone: '',
        verification: ''
      },
      phoneBelonging: 0,
      phoneBelongingList: [
        { text: '+86', value: 0 },
        { text: '+62', value: 1 }
      ],
      verificationShow: true,
      verificationCount: 0,
      loginPopUp: false,
      payAgain: 1
    }
  },
  components: {
    Bubble,
    Login
  },

  computed: {
    ...mapState('user', ['userInfo']),
    // 价钱总和
    totalPrice () {
      let total = 0
      this.selectFoods.forEach((food) => {
        total += food.price * food.count
      })
      return cutThePrice(total)
    },
    // 购买数量
    totalCount () {
      let count = 0
      this.selectFoods.forEach((food) => {
        count += food.count
      })
      return count
    },
    // 结算按钮
    payDesc () {
      if (this.totalPrice === 0) {
        return '请添加菜品'
      } else return '去结算'
    },
    payClass () {
      if (!this.totalCount) return 'not-enough'
      else return 'enough'
    }
  },
  created () {
    this.dropBalls = [] // 隐藏的小球数组
  },
  methods: {
    ...mapMutations('user', ['setUserInfo']),
    ...mapMutations('blance', ['setblanceInfo']),
    // 获取余额
    getUserBalance () {
      this.selectFoods.forEach((food) => {
        food.count = 0
      })
      getUserBalance(this.userInfo.id)
        .then((res) => {
          if (res.code === 0) {
            this.balance = res.object.blance + ''
            console.log(!(this.balance.indexOf('.') === -1))
            if (!(this.balance.indexOf('.') === -1)) {
              const blanceArr = this.balance.split('.')
              if (blanceArr[1].length === 0) {
                blanceArr[1] = blanceArr[1] + '000'
              } else if (blanceArr[1].length === 1) {
                blanceArr[1] = blanceArr[1] + '00'
              } else if (blanceArr[1].length === 2) {
                blanceArr[1] = blanceArr[1] + '0'
              }
              this.balance = blanceArr[0] + '.' + blanceArr[1]
            } else {
              this.balance = this.balance + '.000'
            }
            this.setblanceInfo(this.balance)
          } else {
            this.$toast.fail('获取余额信息失败,请重新登录')
            this.setUserInfo({})
            // this.$router.push({ name: 'Login' })
          }
        })
        .catch((err) => {
          console.log(err)
          this.$toast.fail('获取余额信息失败,请重新登录')
          this.setUserInfo({})
        })
    },
    loginResults (e) {
      if (e === 1) {
        this.loginPopUp = false
      } else {
        this.loginPopUp = false
        this.postOrderData()
        this.$emit('loginResults', e)
      }
    },
    drop (el) {
      // el接收 按钮的位置
      // 驱动transition过渡
      for (let i = 0; i < this.balls.length; i++) {
        const ball = this.balls[i]
        if (!ball.show) {
          ball.show = true
          ball.el = el
          this.dropBalls.push(ball)
          return
        }
      }
    },
    // transition 钩子
    beforeDrop (el) {
      const ball = this.dropBalls[this.dropBalls.length - 1]
      const rect = ball.el.getBoundingClientRect()
      const x = rect.left - 32
      const y = -(window.innerHeight - rect.top - 22) // 初始小球在购物车位置 要放到按钮位置
      // console.log(y)
      el.style.display = ''
      el.style.transform = el.style.webkitTransform = `translate3d(0,${y}px,0)` // 只移动y
      const inner = el.getElementsByClassName(InnerClsHook)[0]
      // console.log(inner)
      inner.style.transform =
        inner.style.webkitTransform = `translate3d(${x}px,0,0)`
    },
    dropping (el, done) {
      this._reflow = document.body.offsetHeight
      el.style.transform = el.style.webkitTransform = 'translate3d(0,0,0)' // 初始位置偏移按钮位置
      const inner = el.getElementsByClassName(InnerClsHook)[0]
      inner.style.transform = inner.style.webkitTransform = 'translate3d(0,0,0)' // 目标位置
      el.addEventListener('transitionend', done)
    },
    afterDrop (el) {
      const ball = this.dropBalls.shift()
      if (ball) {
        ball.show = false
        el.style.display = 'none'
      }
    },
    // 购物车点击事件
    toggleList () {
      if (this.payAgain === 1) {
        if (this.listFold) {
          if (!this.totalCount) {
            return
          }
          this.listFold = false
          this._showShopCartList()
          this._showShopCartSticky()
        } else {
          this.listFold = true
          this._hideShopCartList()
        }
      }
    },
    // 结算点击事件
    pay () {
      if (!this.totalPrice || this.totalPrice === '0') return
      this.$createDialog({
        type: 'confirm',
        content: '确认下单吗?',
        $events: {
          confirm: () => {
            this.payAgain = 2
            console.log(this.userInfo.phone)
            if (this.userInfo.phone) {
              this.postOrderData()
            } else {
              this.createLoginELement()
            }
          }
        }
      }).show()
    },
    // 创建登录弹窗
    createLoginELement () {
      this.loginPopUp = true
    },
    // 展示购物车列表
    _showShopCartList () {
      console.log({
        selectFoods: 'selectFoods'
      })
      this.shopCartListComp =
        this.shopCartListComp ||
        this.$createShopCartList({
          $props: {
            selectFoods: 'selectFoods'
          },
          $events: {
            hide: () => {
              this.listFold = true
            },
            leave: () => {
              this._hideShopCartSticky()
            },
            add: (el) => {
              this.shopCartStickyComp.drop(el)
            }
          }
        })
      this.shopCartListComp.show()
    },
    // 展示顶层购物车组件
    _showShopCartSticky () {
      this.shopCartStickyComp =
        this.shopCartStickyComp ||
        this.$createShopCartSticky({
          $props: {
            selectFoods: 'selectFoods',
            fold: 'listFold',
            list: this.shopCartListComp
          }
        })
      this.shopCartStickyComp.show()
    },
    loginPopUpClose () {
      this.payAgain = 1
    },
    // 隐藏购物车列表
    _hideShopCartList () {
      const comp = this.sticky ? this.$parent.list : this.shopCartListComp
      comp.hide && comp.hide()
    },
    // 隐藏顶层的购物车组件
    _hideShopCartSticky () {
      this.shopCartStickyComp.hide()
    },
    // web发送用户订单请求
    postOrderData () {
      const requestList = []
      const deskList = []
      let totalPrice = 0
      this.selectFoods.forEach((item) => {
        // console.log(item)
        if (item.stock) deskList.push(item) // 包桌放到包桌的数组
        else {
          totalPrice += item.price * item.count
          requestList.push({
            foodName: item.foodName,
            foodCode: item.foodCode,
            price: item.price,
            foodSum: item.count,
            dxamount: item.price * item.count
          })
        }
      })
      const requestData = {
        account: this.userInfo.account,
        buyersName: this.userInfo.userName,
        buyersPhone: this.userInfo.phone,
        amount: totalPrice,
        orderFoodDetailsPo: requestList
      }
      console.log(requestData)
      const deskRequestData = {
        account: this.userInfo.account,
        managementTableId: deskList[0]?.id,
        buyersName: this.userInfo.userName,
        buyersPhone: this.userInfo.phone,
        buyersEdible: deskList[0]?.bagDeskOptionsSelected,
        scheduledTime: deskList[0]?.eatTime,
        dinnerTime: deskList[0]?.dinnerTime
      }
      console.log(deskRequestData)
      // 清空用户信息
      // if (window.localStorage.getItem('userId') === null) {
      //   this.setUserInfo({})
      // }
      // 发送请求订单列表
      if (requestList.length !== 0) {
        postRequestUserOderList(requestData)
          .then((res) => {
            if (res.code === -1) {
              this.$toast(res.message_description)
            } else {
              this.$toast('下单成功')
              this.getUserBalance()
            }
            this.payAgain = 1
            console.log(deskList)
            this.$emit(EVENT_BUY)
          })
          .catch((err) => {
            console.log(err)
            this.$toast.fail('系统有误,请联系管理员')
            this.setUserInfo({})
            this.$emit(EVENT_BUY)
          })
      }
      if (deskList.length !== 0) {
        postBegDeskUserOderList(deskRequestData)
          .then((res) => {
            if (res.code === -1 && requestList.length !== 0) {
              setTimeout(() => {
                this.$toast(res.message_description)
              }, 1500)
            } else if (res.code === -1) {
              this.$toast(res.message_description)
            } else {
              if (requestList.length !== 0) {
                setTimeout(() => {
                  this.$toast('包桌预定成功')
                }, 1500)
              } else {
                this.$toast('包桌预定成功')
              }
              this.payAgain = 1
              this.getUserBalance()
            }
          })
          .catch((err) => {
            console.log(err)
            this.$toast.fail('系统有误,请联系管理员')
            this.setUserInfo({})
            this.$emit(EVENT_BUY)
          })
      }
    }
  },
  watch: {
    fold (newVal) {
      this.listFold = newVal
    },
    totalCount (newValue) {
      if (!this.listFold && !newValue) {
        this._hideShopCartList()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"

.shopcart
  height: 100%
  position: relative
  .content
    display: flex
    background: $color-background
    font-size: 0
    color: $color-light-grey
    .content-left
      flex: 1
      .logo-wrapper
        display: inline-block
        vertical-align: top
        position: relative
        top: -10px
        margin: 0 12px
        padding: 6px
        width: 56px
        height: 56px
        box-sizing: border-box
        border-radius: 50%
        background: $color-background
        .logo
          width: 100%
          height: 100%
          border-radius: 50%
          text-align: center
          background: $color-dark-grey
          &.highlight
            background: $color-blue
          .icon-ziyuan
            line-height: 44px
            font-size: $fontsize-large-xxx
            color: $color-light-grey
            &.highlight
              color: $color-white
        .num
          position: absolute
          top: 0
          right: 0
      .price
        display: inline-block
        vertical-align: top
        margin-top: 12px
        line-height: 24px
        padding-right: 12px
        box-sizing: border-box
        font-weight: 700
        font-size: $fontsize-large
        &.highlight
          color: $color-white
    .content-right
      flex: 0 0 105px
      width: 105px
      .pay
        height: 48px
        line-height: 48px
        text-align: center
        font-weight: 700
        font-size: $fontsize-small
        &.not-enough
          background: $color-dark-grey
        &.enough
          background: $color-green
          color: $color-white
  .ball-container
    .ball
      position: fixed
      left: 32px
      bottom: 22px
      z-index: 200
      transition: all 0.4s cubic-bezier(0.49, -0.29, 0.75, 0.41)
      .inner
        width: 16px
        height: 16px
        border-radius: 50%
        background: $color-blue
        transition: all 0.4s linear

.order-wrap
  height 100%
@media screen and (min-width: 300px) and (max-width: 1000px){
  .login-popup{
    width 90%
    height 50%
  }
}
@media screen and (min-width: 1000px) {
  .login-popup{
    width 40%
    height 60%
  }
}
</style>
