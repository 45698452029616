<template>
  <dir class="tab">
    <cube-tab-bar
      :showSlider="true"
      v-model="selectedLabel"
      :useTransition="false"
      :data="tabs"
      ref="tabBar"
      class="border-bottom-1px"
      @change="changeTabs"
    ></cube-tab-bar>
    <div class="slide-wrapper">
      <cube-slide
        :loop="false"
        :auto-play="false"
        :show-dots="false"
        :initial-index="index"
        ref="slide"
        @change="onChange"
        @scroll="onScroll"
        :options="slideOptions"
      >
        <cube-slide-item v-for="(tab, index) in tabs" :key="index">
          <components
            :is="tab.component"
            :data="tab.data"
            ref="component"
          ></components>
        </cube-slide-item>
      </cube-slide>
    </div>
  </dir>
</template>

<script>
import Goods from 'components/goods/goods'
import Order from 'components/order/order'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'tab',
  props: {
    tabs: {
      // 传来的tab列表
      type: Array,
      default () {
        return []
      }
    },
    initialIndex: {
      type: Number,
      default: 0
    }
  },
  components: {
    Goods,
    Order
  },
  data () {
    return {
      index: this.initialIndex, // 当前索引
      slideOptions: {
        listenScroll: true,
        probeType: 3,
        directionLockThreshold: 0
      }
    }
  },
  mounted () {
    this.onChange(this.initialIndex)
  },
  computed: {
    ...mapState('user', ['userInfo']),
    // 当前选中
    selectedLabel: {
      get () {
        return this.tabs[this.index].label
      },
      set (newValue) {
        this.index = this.tabs.findIndex((value) => {
          return value.label === newValue
        })
      }
    }
  },
  methods: {
    ...mapMutations('user', ['setUserInfo']),
    ...mapMutations('desk', ['setDeskInfo']),
    changeTabs () {
      // this.setUserInfo({})
      // this.setDeskInfo({})
    },
    // 改变选中项
    onChange (current) {
      // if (!this.userInfo.userId) {
      //   this.setUserInfo({})
      //   this.setDeskInfo({})
      // }
      this.index = current
      const component = this.$refs.component[current]
      component.bagDeskQuestData && (component.bagDeskQuestData.eatTime = '')
      component.currentChooseTime && (component.currentChooseTime = '')
      component.getGoodsData && component.getGoodsData()
      if (component.requestOrderData && this.userInfo.userId) {
        component.requestOrderData = {
          pageSize: 10,
          pageNum: 1,
          buyersPhone: this.userInfo.phone
        }
        component.getUserOder()
      }
      !isNaN(component.radioOptionsSelected) &&
        (component.radioOptionsSelected = 1)
    },
    // 计算滑动距离赋值tabBar
    onScroll (pos) {
      const tabBarWidth = this.$refs.tabBar.$el.clientWidth
      const slideWidth = this.$refs.slide.slide.scrollerWidth
      const tranform = (-pos.x / slideWidth) * tabBarWidth
      this.$refs.tabBar.setSliderTransform(tranform)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/variable"
.tab
  margin: 0
  .active
    color: $color-blue
  >>> .cube-tab
    padding 10px 0
  display: flex
  flex-direction: column
  padding 0
  height: 100%
  .slide-wrapper
    height: 100%
    flex: 1
    overflow hidden
</style>
