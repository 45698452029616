<template>
  <div class="goods">
    <div class="scroll-nav-wrapper">
      <div
        v-if="blanceInfo"
        style="
          font-size: 12px;
          color: #080808;
          font-weight: bold;
          margin-left: 65%;
          width: 30%;
        "
      >
        <span>余额(RP) : </span>
        <span>{{ this.blanceInfo }}</span>
      </div>
      <!-- 左右联动scroll -->
      <cube-scroll-nav
        :side="true"
        :data="goods"
        :options="scrollOptions"
        v-if="goods.length"
      >
        <template slot="bar" slot-scope="props">
          <cube-scroll-nav-bar
            direction="vertical"
            :labels="props.labels"
            :txts="barTxts"
            :current="props.current"
          >
            <template slot-scope="props">
              <div class="text">
                <span class="text-info">{{ props.txt.name }}</span>
                <span class="num" v-if="props.txt.count">
                  <bubble :num="props.txt.count"></bubble>
                </span>
              </div>
            </template>
          </cube-scroll-nav-bar>
        </template>
        <cube-scroll-nav-panel
          v-for="(good, goodIndex) in goods"
          :key="good.name"
          :label="good.name"
        >
          <ul v-if="goodIndex != goods.length - 1" class="food-container">
            <li
              v-for="(food, index) in good.data"
              :key="index"
              class="food-item"
            >
              <div class="icon">
                <img width="57" height="57" :src="food.imgUrl" alt="" />
              </div>
              <div class="content">
                <h2 class="name">{{ food.foodName }}</h2>
                <div class="price">RP {{ food.priceView }}</div>
                <div class="remaining">
                  今日剩余：<span>{{ food.foodSum }}</span
                  >份
                </div>
                <div class="cart-control-wrapper">
                  <!-- onAdd 驱动购物车 小球飞入动画 -->
                  <cart-control
                    v-if="food.foodSum !== 0"
                    @add="onAdd"
                    :food="food"
                    v-show="food.eligibleTime"
                  ></cart-control>
                  <div class="food-empty" v-else>今日售罄</div>
                </div>
              </div>
            </li>
          </ul>
          <template v-else>
            <div class="time-choose">
              <van-cell-group inset>
                <van-cell
                  title="请选择包桌时间："
                  @click="datetimePickerShow = true"
                  v-model="bagDeskQuestData.eatTime"
                />
              </van-cell-group>
              <ul class="food-container">
                <li
                  v-for="(desk, index) in good.data"
                  :key="index"
                  class="food-item"
                  @click.stop="goDetail(desk, $event)"
                >
                  <div class="icon">
                    <img width="57" height="57" :src="desk.imgUrl" alt="" />
                  </div>
                  <div class="content">
                    <h2 class="name">{{ desk.tableName }}</h2>
                    <div class="price">RP {{ desk.priceView }}</div>
                    <div class="remaining">
                      今日剩余：<span>{{ desk.stock }}</span
                      >桌
                    </div>
                    <div class="cart-control-wrapper">
                      <!-- {{ desk.eligibleTime }} -->
                      <cart-control
                        v-if="desk.stock !== 0"
                        @add="onAdd"
                        :food="desk"
                        :deskTime="bagDeskQuestData.eatTime"
                        :dinnerTime="dinnerTime"
                        v-show="desk.eligibleTime"
                      ></cart-control>
                      <div class="food-empty" v-else>今日售罄</div>
                    </div>
                    <div class="bagDesk-options-wrap">
                      <cube-radio-group
                        v-model="desk.bagDeskOptionsSelected"
                        :options="bagDeskOptions"
                        :col-num="2"
                        :hollow-style="true"
                      />
                    </div>
                    <div style="font-size: 14px" @click.stop>
                      <span>用餐时段：</span>
                      <select v-model="dinnerTime">
                        <option
                          :value="item"
                          :key="index"
                          v-for="(item, index) in dinnerTimeList"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 时间选择器 -->
            <van-popup
              v-model="datetimePickerShow"
              get-container="body"
              round
              position="bottom"
            >
              <van-DatetimePicker
                v-model="currentChooseTime"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                v-show="datetimePickerShow"
                @cancel="datetimePickerShow = false"
                @confirm="chooseTime"
              ></van-DatetimePicker>
            </van-popup>
            <!-- 菜品展示 -->
            <van-popup
              v-model="deskInfoShow"
              get-container="body"
              style="width: 95%"
              round
            >
              <cube-scroll
                class="desk-info-content"
                ref="deskInfoContent"
                :options="scrollOptions"
              >
                <div class="main">
                  <div class="food-name">
                    <span>菜品名称</span>
                    <span>菜品数量</span>
                    <!-- <span>菜品单价</span> -->
                  </div>
                  <div class="food-wrap">
                    <div
                      class="food-container"
                      v-for="(item, index) in deskInfoData.foodList"
                      :key="index"
                    >
                      <div class="food-container-top">
                        <div class="food-container-title">
                          <!-- <img v-show="item.imgUrl" width="57" height="57" :src="item.imgUrl" alt="" /> -->
                          <div class="food-index">{{ index + 1 }}.</div>
                          <div class="food-title">{{ item.foodName }}</div>
                        </div>
                        <div class="food-num">
                          <span>x</span>{{ item.number }}
                        </div>
                        <!-- <div class="food-price">RP {{ item.priceView }}</div> -->
                      </div>
                      <!-- <div class="food-container-bottom">
                        {{ item.categoryName }}
                      </div> -->
                    </div>
                    <div class="total-price">
                      餐桌金额： <span>RP：{{ deskInfoData.priceView }}</span>
                    </div>
                  </div>
                </div>
              </cube-scroll>
            </van-popup>
          </template>
        </cube-scroll-nav-panel>
      </cube-scroll-nav>
    </div>
    <div class="shop-cart-wrapper">
      <shop-cart
        ref="shopCart"
        @buy="onBuy"
        @loginResults="loginResults"
        :select-foods="selectFoods"
      ></shop-cart>
    </div>
  </div>
</template>

<script>
import {
  getGoodsList,
  getGoodsItemImg,
  getBagDeskList
} from 'api'
import CartControl from 'components/cart-control/cart-control'
import ShopCart from 'components/shop-cart/shop-cart'
import { cutThePrice } from '@/utils/tool.js'
import Bubble from 'components/bubble/bubble'
import { mapMutations, mapState } from 'vuex'
export default {
  components: {
    ShopCart,
    CartControl,
    Bubble
  },
  data () {
    return {
      balance: '',
      goods: [],
      balls: [], // 隐藏的小球数量
      requestData: { pageSize: 10, pageNum: 1 },
      scrollOptions: {
        // scroll插件选项
        click: false,
        directionLockThreshold: 0
      },
      bagDeskQuestData: {
        // 请求包桌请求数据
        pageSize: 10,
        pageNum: 1,
        eatTime: ''
      },
      datetimePickerShow: false, // 是否显示时间选择器
      deskInfoShow: false,
      deskInfoData: {},
      currentChooseTime: '', // 当前选择时间
      dinnerTimeList: ['17:30-18:30', '18:30-19:30'],
      dinnerTime: '17:30-18:30',
      bagDeskOptions: [
        { label: '堂食', value: 1 },
        { label: '外带', value: 0 }
      ]
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('blance', ['blanceInfo']),
    selectFoods () {
      const arr = []
      this.goods.forEach((item) => {
        item.data.forEach((food) => {
          if (food.count) {
            arr.push(food)
          }
        })
      })
      return arr
    },
    barTxts () {
      const ret = []
      this.goods.forEach((good) => {
        const { name, data } = good
        let count = 0
        data.forEach((food) => {
          count += food.count || 0
        })
        ret.push({
          name,
          count
        })
      })
      return ret
    },
    minDate () {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth()
      const day = date.getDate()
      return new Date(year, month, day)
    },
    maxDate () {
      const date = new Date()
      const year = date.getFullYear() + 1
      const month = date.getMonth()
      const day = date.getDate()
      return new Date(year, month, day)
    }
  },
  // watch () {
  //   userInfo(oldV, newV ){

  //   }
  // },
  methods: {
    ...mapMutations('desk', ['setDeskInfo']),
    ...mapMutations('eatTime', ['setEatTimeInfo']),
    loginResults (e) {
      // this.getUserBalance()
    },
    // 获取菜品列表
    async getGoodsData () {
      // if (!this.fetchedGoodsData) {
      //   this.fetchedGoodsData = true // 获取过一次不会再获取
      //   const { object: data } = await getGoodsList(this.requestData)
      //   this.getEligibleGoods(data)
      //   this.goods = await this.getGoodsImg(data)
      // }
      const { object: data } = await getGoodsList(this.requestData)
      console.log(data)
      // 向vuex设置可订餐的时间
      let startEatTime = data[0].data[0].ordemealCheckTime
      let endEatTime = data[0].data[0].ordemealSinceTime
      startEatTime = Array.from(startEatTime)
      endEatTime = Array.from(endEatTime)
      startEatTime.splice(-3, 3)
      endEatTime.splice(-3, 3)
      startEatTime = startEatTime.join('')
      endEatTime = endEatTime.join('')
      this.setEatTimeInfo({
        startEatTime,
        endEatTime
      })
      await this.getBagDeskData(data)
      this.getEligibleGoods(data)
      this.goods = await this.getGoodsImg(data)
      console.log(this.goods, '*********************')
    },
    // 获取包桌列表
    async getBagDeskData (data) {
      console.log(data, '123456798')
      // let bagDesk = []
      // console.log(data.length)
      const res = await getBagDeskList(this.bagDeskQuestData)
      res.object.list.forEach((item) => {
        item.ordemealCheckTime = '08:00:00'
        item.ordemealSinceTime = '15:00:00' // 包桌时间
      })
      data[data.length] = { name: '包桌', data: res.object.list }
    },
    // 符合时间的菜品
    getEligibleGoods (data, isOther) {
      // console.log(data)
      if (isOther) {
        for (let i = 0; i < data.length; i++) {
          data[i].eligibleTime = this.thinkTime(
            data[i].ordemealCheckTime,
            data[i].ordemealSinceTime
          )
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          // console.log(data[i])
          // let EligibleArr = []
          data[i].data.forEach((item) => {
            item.eligibleTime = this.thinkTime(
              item.ordemealCheckTime,
              item.ordemealSinceTime
            )
            // item.eligibleTime = true
          })
        }
      }
    },
    // 判断当时时间是否在事件段内
    thinkTime (start, end) {
      const startStr = start.split(':')
      const endStr = end.split(':')
      const startTime = new Date()
      const endTime = new Date()
      const nowTime = new Date()
      startTime.setHours(startStr[0])
      startTime.setMinutes(startStr[1])
      startTime.setSeconds(startStr[2])
      endTime.setHours(endStr[0])
      endTime.setMinutes(endStr[1])
      endTime.setSeconds(endStr[2])
      if (
        nowTime.getTime() - startTime.getTime() > 0 &&
        nowTime.getTime() - endTime.getTime() < 0
      ) {
        return true
      } else {
        return false
      }
    },
    // 获取菜品图片
    async getGoodsImg (arr, chooseTime) {
      if (!chooseTime) {
        for (let i = 0; i < arr.length; i++) {
          const dataList = arr[i].data
          // console.log(dataList)
          for (let k = 0; k < dataList.length; k++) {
            const { object } = await getGoodsItemImg(dataList[k].picture)
            dataList[k].imgUrl = object
            // dataList[k].dinnerTime = ''
            this.$set(dataList[k], 'priceView', cutThePrice(dataList[k].price))
            // bagDeskOptionsSelected
            if ('tableName' in dataList[k]) {
              dataList[k].bagDeskOptionsSelected = ''
            }
          }
        }
      } else {
        for (let k = 0; k < arr.length; k++) {
          const { object } = await getGoodsItemImg(arr[k].picture)
          arr[k].imgUrl = object
          this.$set(arr[k], 'priceView', cutThePrice(arr[k].price))
        }
      }
      return arr
    },
    // 小球飞入 驱动方法
    onAdd (el) {
      this.$refs.shopCart.drop(el)
    },
    // 购买菜品后
    async onBuy () {
      // console.log('1234567897894')
      this.goods.length = 0
      this.bagDeskQuestData.eatTime = ''
      this.currentChooseTime = ''
      this.setDeskInfo({})
      await this.getGoodsData(true)
    },
    // 选择时间
    async chooseTime (e) {
      console.log('123456')
      const year = this.currentChooseTime.getFullYear()
      const month =
        this.currentChooseTime.getMonth() + 1 < 10
          ? '0' + (this.currentChooseTime.getMonth() + 1)
          : this.currentChooseTime.getMonth() + 1
      const day = this.currentChooseTime.getDate()
      this.bagDeskQuestData.eatTime = year + '-' + month + '-' + day
      try {
        const toast = this.$createToast({
          txt: '请稍后'
        }).show()
        const res = await getBagDeskList(this.bagDeskQuestData)
        res.object.list.forEach((item) => {
          item.ordemealCheckTime = '08:00:00'
          item.ordemealSinceTime = '15:00:00' // 包桌时间
        })
        this.getEligibleGoods(res.object.list, true)
        await this.getGoodsImg(res.object.list, true)
        this.goods[this.goods.length - 1].data = res.object.list
        toast.hide()
        this.datetimePickerShow = false
      } catch (error) {
        this.$createToast({
          txt: '包桌选择异常,请联系管理员',
          time: 1500
        }).show()
        console.log(error)
      }
    },
    // 取消选择时间
    cancelChooseTime () {
      this.bagDeskQuestData.eatTime = ''
      this.datetimePickerShow = false
    },
    async goDetail (data, e) {
      if (e.target._prevClass === 'cube-radio-input') return
      const detailObject = Object.assign({}, data)
      await this.getGoodsImg(detailObject.foodList, true)
      this.deskInfoShow = true
      this.deskInfoData = detailObject
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/mixin"
@import "~common/stylus/variable"
.goods
  position: relative
  text-align: left
  height: 100%
  .scroll-nav-wrapper
    position: absolute
    width: 100%
    top: 0
    left: 0
    bottom: 48px
  >>> .cube-scroll-nav-bar
    width: 80px
    white-space: normal
    overflow: hidden
    display: flex
    justify-content: center
    background: $color-background-ssss
    min-height: 300px
  >>> .cube-scroll-nav-bar-item
    padding: 0 10px
    display: flex
    align-items: center
    height: 56px
    line-height: 14px
    font-size: $fontsize-small
    background: $color-background-ssss
    .text
      flex: 1
      position: relative
      .text-info
        display: block
        width 60px
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
    .num
      position: absolute
      right: -8px
      top: -10px
  >>> .cube-scroll-nav-bar-item_active
    background: $color-white
    color: $color-dark-grey
    font-size: $fontsize-medium
    span
      font-weight: 700
  >>> .cube-scroll-nav-panel-title
    padding-left: 14px
    height: 26px
    line-height: 26px
    border-left: 2px solid $color-col-line
    font-size: $fontsize-small
    color: $color-blue
    // background: $color-background-ssss
    background: $color-white
    font-weight: 700

  @media screen and (min-width: 600px) and (max-width: 1000px){
    .food-container{
      display: flex
      flex-wrap: wrap
      justify-content: space-between
    }
    .food-item {
      width 40%
    }
  }
  @media screen and (min-width: 1000px) {
    .food-container{
      display: flex
      flex-wrap: wrap
    }
    .food-item {
      width 30%
    }
  }
  .food-item
    display: flex
    margin: 15px
    position: relative
    &:last-child
      border-none()
    .icon
      flex: 0 0 57px
      margin-right: 10px
    .content
      flex: 1
      .name
        margin: 2px 0 8px 0
        height: 14px
        line-height: 14px
        font-size: $fontsize-medium
        color: $color-dark-grey
      .price
        font-weight: 700
        line-height: 24px
        margin-right: 8px
        font-size: $fontsize-small
        color: $color-red
      .remaining
        font-size: $fontsize-small
        span
          color: $color-red
      .bagDesk-options-wrap
        display: flex
        >>> .cube-radio-group
          border:0px !important
          font-size: $fontsize-medium
        >>> .cube-radio
          padding-left:0
          padding-right:15px
        >>> .border-top-1px::before
          border:none
        >>> .cube-radio-group::after
          border:none
      .cart-control-wrapper
        position: absolute
        right: 0
        top: 50%
        transform: translate(0,-50%)
        .food-empty
          font-size: $fontsize-small
          padding: 4px
          background: #ccc
          color: $color-white
          border-radius: 5px
  .shop-cart-wrapper
    position: absolute
    left: 0
    bottom: 0
    z-index: 50
    width: 100%
    height: 48px
</style>
