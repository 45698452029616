<template>
  <div class="cartcontrol">
    <transition name="move">
      <div class="cart-decrease" v-show="food.count > 0" @click.stop="decrease">
        <span class="inner icon-remove-circle-outline"></span>
      </div>
    </transition>
    <div class="cart-count" v-show="food.count > 0">{{ food.count }}</div>
    <div class="cart-add icon-add-circle" @click.stop="add"></div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

const EVENT_ADD = 'add'

export default {
  name: 'cart-control',
  props: {
    food: {
      type: Object
    },
    deskTime: {
      type: String
    },
    dinnerTime: {
      type: String
    }
  },
  computed: {
    ...mapState('desk', ['deskInfo'])
  },
  methods: {
    ...mapMutations('desk', ['setDeskInfo']),
    // 增加
    add (evnet) {
      if (!this.food.count && this.food.foodSum) {
        this.$set(this.food, 'count', 1)
      } else if (!this.food.count && this.food.stock) {
        if (!this.dinnerTime) {
          this.$createToast({
            txt: '请选择用餐时段',
            time: 1500,
            type: 'error'
          }).show()
          return
        }
        if (!this.deskTime) {
          this.$createToast({
            txt: '请选择包桌时间',
            time: 1500,
            type: 'error'
          }).show()
          return
        }
        if (isNaN(this.food.bagDeskOptionsSelected)) {
          this.$createToast({
            txt: '请选择食堂/外带',
            time: 1500,
            type: 'error'
          }).show()
          return
        }
        if (this.deskInfo.stock) {
          this.$createToast({
            txt: '只能预定一个包桌',
            time: 1500,
            type: 'error'
          }).show()
          return
        }
        this.$set(this.food, 'count', 1)
        this.$set(this.food, 'eatTime', this.deskTime)
        this.$set(this.food, 'dinnerTime', this.dinnerTime)
        this.setDeskInfo(this.food) // 存储包桌信息到vuex
        console.log('add', this.food)
      } else {
        if (this.food.count + 1 <= this.food.foodSum && this.food.foodSum) {
          this.food.count++
        } else if (this.food.count + 1 <= 1 && this.food.stock) {
          if (!this.deskTime) {
            this.$createToast({
              txt: '请选择包桌时间',
              time: 1500
            }).show()
            return
          }
          if (!this.food.bagDeskOptionsSelected) {
            this.$createToast({
              txt: '请选择食堂/外带',
              time: 1500
            }).show()
            return
          }
          this.food.count++
        } else return
        // this.food.count++
      }
      this.$emit(EVENT_ADD, evnet.target) // 把点击按钮的dom派发出去
    },
    // 减少
    decrease () {
      if (this.food.count) {
        this.food.count--
        // 清空的是包桌并且包桌和vuex存储的是同一个包桌
        if (this.food === this.deskInfo) {
          this.food.eatTime = ''
          this.setDeskInfo({})
        }
        console.log('remove', this.food)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~common/stylus/variable"

.cartcontrol
  display: flex
  align-items: center
  .cart-decrease
    display: inline-block
    padding: 6px
    opacity: 1
    .inner
      display: inline-block
      line-height: 24px
      font-size: $fontsize-large-xxx
      color: $color-blue
      transition: all 0.4s linear
      transform: rotate(0)
    &.move-enter-active, &.move-leave-active
      transition: all 0.4s linear
    &.move-enter, &.move-leave-active
      opacity: 0
      transform: translate3d(24px, 0, 0)
      .inner
        transform: rotate(180deg)
  .cart-count
    width: 12px
    line-height: 24px
    text-align: center
    font-size: $fontsize-small-s
    color: $color-grey
    font-weight: 700
  .cart-add
    display: inline-block
    padding: 6px
    line-height: 24px
    font-size: $fontsize-large-xxx
    color: $color-blue
</style>
